<template>
  <div class="RoomList">
    <div class="header">
      <el-input
        size="small"
        placeholder="搜索"
        prefix-icon="el-icon-search"
        v-model="search"
        class="search"
        :clearable="true"
        @input="startSearch"
      >
      </el-input>

      <el-tooltip effect="dark" content="創建直播" placement="bottom">
        <el-button
          size="small"
          icon="el-icon-plus"
          circle
          @click="goCreate"
        ></el-button>
      </el-tooltip>
    </div>
    <div class="list" v-loading="loading">
      <el-scrollbar>
        <div
          v-for="(room, key) in showRooms"
          @click="go(room)"
          :key="key"
          :class="{ active: currentId == room.id }"
          class="item"
        >
          <el-avatar
            shape="square"
            :size="'large'"
            :src="videoLogo"
          ></el-avatar>
          <div class="item-desc">
            <p class="item-title">
              {{ room.title }}
              <span v-if="!room.status" :class="{ disable: !room.status }"
                >（已結束）</span
              >
            </p>
            <p class="item-live_id">Live ID：{{ room.live_id }}</p>
          </div>
          <span class="item-id">#{{ room.id }}</span>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<script>
import videoLogo from "../../../assets/images/video_logo.jpg";

export default {
  name: "RoomList",

  props: {
    refresh: String,
  },

  data() {
    return {
      search: null,
      videoLogo,
      currentId: null,
      rooms: [],
      showRooms: [],
      loading: true,
    };
  },

  watch: {
    "$route.params.id"() {
      this.init();
    },

    refresh() {
      this.getRooms();
    },
  },

  created() {
    this.init();
    this.getRooms();
  },

  methods: {
    init() {
      this.currentId = this.$route.params.id;
    },

    async getRooms() {
      this.loading = true;

      const result = await this.$fetch("room");
      if (!result.isOk) {
        this.$message.error(result.message);
        return;
      }

      this.rooms = result.data;
      this.startSearch();

      this.loading = false;
    },

    goCreate() {
      if (this.$route.path == "/room/create") {
        return;
      }

      this.$router.push("/room/create");
    },

    go(room) {
      const path = "/room/" + room.id;
      if (this.$route.path == path) {
        return;
      }

      this.$router.push(path);
    },

    startSearch() {
      const search = this.search;
      if (!search) {
        this.showRooms = this.rooms;
        return;
      }

      this.showRooms = this.rooms.filter((room) => {
        if (
          room.title.indexOf(search) > -1 ||
          room.live_id.indexOf(search) > -1
        ) {
          return true;
        }

        return false;
      });
    },
  },
};
</script>
<style scoped lang="less">
.RoomList {
  width: 250px;
  height: 100%;
  background: #efefef;
  display: inline-block;

  .header {
    padding: 10px;
    height: 32px;
    border-bottom: 1px #ddd solid;

    .search {
      margin-right: 8px;
      width: 190px;
    }
  }

  .list {
    height: 732px;
  }

  .item {
    padding: 10px;
    position: relative;
    display: flex;
    align-content: center;
    border-bottom: 1px dashed #ddd;

    &:last-child {
      border: none;
    }

    &.active {
      background: #e3e3e3;
    }

    &:hover {
      background: #e3e3e3;
      cursor: pointer;
    }

    .item-desc {
      padding-left: 5px;
      display: inline-block;

      p {
        height: 20px;
        line-height: 20px;
        margin: 0;
        padding: 0;

        &.item-live_id {
          font-size: 12px;
          color: #a1a1a1;
        }
      }

      .item-title {
        span {
          font-size: 12px;

          &.disable {
            color: red;
          }
        }
      }
    }

    .item-id {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #a1a1a1;
    }
  }
}
</style>