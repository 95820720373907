<template>
  <div class="Comment">
    <RoomList />
    <div></div>
  </div>
</template>
<script>
import RoomList from "../Room/List";

export default {
  name: "Comment",

  components: {
    RoomList,
  },

  created() {
    this.init();
  },

  data() {
    return {};
  },

  methods: {
    init() {},
  },
};
</script>
<style scoped lang="less">
.Comment {
  height: 100%;
}
</style>